import { Routes} from '@angular/router';
import { homeComponent } from './pages/Home/home';



export const AppRoutes : Routes = [
      {
        path:'',
        component:homeComponent,
        pathMatch:'full'

      },
      {
        path: '',
        loadChildren: () => import('./FeaturedModules/others.module').then(m => m.othersModule)
      },
      {
        path: '',
        loadChildren: () => import('./FeaturedModules/products.module').then(m => m.productsModule)
      },
      // {
      //   path: '',
      //   loadChildren: () => import('./FeaturedModules/cart.module').then(m => m.cartModule)
      // },
      // {
      //   path: '',
      //   loadChildren: () => import('./FeaturedModules/payment.module').then(m => m.paymentModule)
      // },
      // {
      //   path: '',
      //   loadChildren: () => import('./FeaturedModules/profile.module').then(m => m.profileModule)
      // },
      // {
      //   path: '',
      //   loadChildren: () => import('./FeaturedModules/loginRegistration.module').then(m => m.loginModule)
      // },
      {
        path: '**',
        redirectTo:'/',
      },
]
