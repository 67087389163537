import { Component,OnInit } from '@angular/core';
import { sharedService } from '../../Services/shared.service';
import { environment } from "../../../../environments/environment";

declare const $;

@Component({
    selector:'app-footer',
    templateUrl: './footer.html',
})

export class footerComponent implements OnInit {
    public address:string;
    public app_store:string;
    public contact_number:string;
    public whatsapp_number:string;
    public open_hours:string;
    public email_id:string;
    public facebook:string;
    public instagram:string;
    public play_store:string;
    public snapchat:string;
    public twitter:string;
    public youtube:string;
    public free_shipping:string;
    public logged_in:boolean=false;
    public career:string="https://careers.saadeddin.com";
    public LANG:any;
    public see_more:boolean=true;
    public arrow:boolean=true;
    public assets_path="https://saadeddin-production-static.s3.ap-south-1.amazonaws.com/assets"



    constructor(private shared:sharedService){
        this.shared.getLanguage().subscribe((res) => {
            this.changeLanguage();
          })
        this.changeLanguage()
        this.shared.currentUser.subscribe(user=>this.logged_in=user);
    }

    ngOnInit(){
        if(localStorage.getItem('saadeddin_logged_in') != undefined){
            this.logged_in=true;
            this.shared.changeUser(true);
        }

        if($(window).width() < 962)
          {
            this.see_more=false;
            this.arrow=false
          }

    }

    changeLanguage=()=>{
          this.LANG = environment.english_translation;

        // if (localStorage.getItem("arabic") == "true" && localStorage.getItem("arabic") != null) {
        //   this.LANG = environment.arabic_translation;
        // }
        // else {
        //   this.LANG = environment.english_translation;
        // }
      }

    sendData=(data: any)=> {
        this.address=data['address'];
        this.app_store=data['app_store'];
        this.contact_number=data['contact_number'];
        this.whatsapp_number=data['whatsapp_number'];
        this.open_hours=data['business_hours'];
        this.email_id=data['email_id'];
        this.facebook=data['facebook'];
        this.instagram=data['instagram'];
        this.play_store=data['play_store'];
        this.snapchat=data['snapchat'];
        this.twitter=data['twitter'];
        this.youtube=data['youtube'];
        this.free_shipping=data['free_shipping_limit'];
        // console.log(this.app_store);
        
    }


    scrollTop=()=>{
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      })
    }


    contactUser=()=>{
        let mobile=this.contact_number
        location.href="tel:"+mobile
        
      }

      openWhatsApp=()=>{
        let url;
        if($(window).width() < 769)
          {
            url="https://wa.me/"+this.whatsapp_number;               
          }else{
            url="https://web.whatsapp.com/send?phone="+this.whatsapp_number
          }
          window.open(url,"whatsapp");
        }


      ShowFooterLinks=()=>{
      $(".links_row_footer").toggleClass('show');
      }

      mailTo=()=>{
        let url="https://mail.google.com/mail/?view=cm&fs=1&to="+this.email_id;
          let newwindow=window.open(url,'name','height=500,width=520,top=200,left=300,resizable');
            if (window.focus) {
              newwindow.focus()
            }
      }

      openTermsConditions=()=>{ 
        $("#terms-conditions").modal("show")
    }

    openPrivacyPolicy=()=>{ 
        $("#privacy-policy").modal("show")
    }

    openQualityPolicy=()=>{ 
      $("#quality-policy").modal("show")
  }

  openCollapse=()=>{
    this.arrow=!this.arrow
  }

} 